export const environment = {
  name: 'dev',
  sysLogApiKey: 'todo',
  urls: {
    api: 'https://dev.dlassessments.jabilapps.com/dev/',
    reportingApi: 'https://2c8v0dbvz6.execute-api.eu-central-1.amazonaws.com/'
  },
  okta: {
    issuer: 'https://jabildev.oktapreview.com',
    clientId: '0oa1qejfcrnWO9Zx70h8',
    redirectUri: 'https://dev.dlassessments.jabilapps.com/login/callback',
    scopes: ["openid", "email", "profile", "groups"],
  },
  push: {
    subject: "mailto: <piotr_czepek@jabil.com>",
    publicKey: "BGTZ7TEAEnB2pj3sVHyGR6_Ah5sKcv9EFcetQahz9ZXopxEQ5Ouy_jqhDy_ZEjRhxL4FtEYP_Fw1c8qfSS3tNUo",
    api: "https://besq8hiux5.execute-api.eu-central-1.amazonaws.com/api"
  }
};
